import {
    createRouter,
    createWebHistory
} from 'vue-router'

const routes = [
    {
        path: '/:thread?',
        name: 'home',
        component: () => import('@/pages/Home')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
