import StorageService from '@/support/services/storage'
import DataService from '@/support/services/data'

const STORAGE_KEY = 'threads'

const theme = {
    namespaced: true,
    state: () => ({
        threads: []
    }),
    getters: {
        threads(state) {
            return state.threads
        }
    },
    actions: {
        async loadThreads({commit}) {
            await StorageService.local.get(STORAGE_KEY)
                .then(cachedThreads => {
                    if (cachedThreads) return commit('SET_THREADS', cachedThreads)
                })
                .catch(() => {
                })
        },
        saveThread({commit}, thread) {
            commit('SAVE_THREAD', thread)
        },
        deleteThread({commit}, thread) {
            commit('DELETE_THREAD', thread)
        }
    },
    mutations: {
        SAVE_THREAD(state, thread) {
            DataService.insert(state.threads, thread, 'access_key')

            StorageService.local.set(STORAGE_KEY, state.threads)
        },
        SET_THREADS(state, threads) {
            DataService.insert(state.threads, threads, 'access_key')
        },
        DELETE_THREAD(state, thread) {
            DataService.delete(state.threads, thread, 'access_key')

            StorageService.local.set(STORAGE_KEY, state.threads)
        }
    }
}

export default theme
