<template>
  <header class="flex bg-white fixed top-0 right-0 w-full shadow-lg items-center justify-center dark:bg-slate-700">
    <div class="flex container mx-auto px-4 items-center justify-end sm:px-0">
      <theme-toggler/>
    </div>
  </header>
</template>

<script>
import ThemeToggler from './Header/ThemeToggler'

export default {
  name: 'AppHeader',
  components: {
    ThemeToggler
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

header {
  height: $headerHeight;
}
</style>
