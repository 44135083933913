import axios from 'axios'

const formDataConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

const HttpService = {
    init() {
        axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL
    },
    get(url, config) {
        return axios.get(url, config)
    },
    post(url, data, config) {
        return axios.post(url, data, config)
    },
    postFormData(url, data, config) {
        return axios.post(url, data, {
            ...formDataConfig,
            ...config
        })
    },
    put(url, data) {
        return axios.put(url, data)
    },
    putFormData(url, data, config) {
        return axios.put(url, data, {
            ...formDataConfig,
            ...config
        })
    },
    delete(url) {
        return axios.delete(url)
    }
}

export default HttpService
