<template>
  <app-header/>
  <div id="main" class="bg-slate-100 dark:bg-slate-500 md:p-8">
    <div class="container mx-auto h-full">
      <router-view :key="$route.path"/>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import AppHeader from '@/components/app/AppHeader'

export default {
  methods: {
    ...mapActions({
      loadThemeAction: 'theme/loadTheme',
      loadThreadsAction: 'threads/loadThreads',
    })
  },
  components: {
    AppHeader
  },
  created() {
    this.loadThemeAction()
    this.loadThreadsAction()
  }
}
</script>

<style lang="scss">
@import 'assets/scss/variables';
@import 'assets/scss/transitions';

html, body, #app {
  height: 100%;
  min-height: 100%;
}

#app {
  padding-top: $headerHeight;
}
</style>

<style lang="scss" scoped>
@import 'assets/scss/variables';

#main {
  height: 100%;
  overflow: auto;
}
</style>
