import {
    DARK_THEME,
    LIGHT_THEME,
    SYSTEM_THEME
} from '@/support/constants/theme'
import StorageService from '@/support/services/storage'

const STORAGE_KEY = 'theme'

const theme = {
    namespaced: true,
    state: () => ({
        theme: null
    }),
    getters: {
        theme(state) {
            return state.theme
        }
    },
    actions: {
        async loadTheme({dispatch}) {
            await StorageService.local.get(STORAGE_KEY)
                .then(cachedTheme => {
                    if (cachedTheme) return dispatch('setTheme', cachedTheme)

                    dispatch('setTheme', SYSTEM_THEME)
                })
                .catch(() => dispatch('setTheme', SYSTEM_THEME))
        },
        setTheme({commit}, theme) {
            commit('SET_THEME', theme)
        },
        toggleTheme({dispatch}) {
            StorageService.local.get(STORAGE_KEY)
                .then(currentTheme => {
                    switch (currentTheme) {
                        case DARK_THEME:
                            dispatch('setTheme', LIGHT_THEME)
                            break
                        case LIGHT_THEME:
                            dispatch('setTheme', SYSTEM_THEME)
                            break
                        case SYSTEM_THEME:
                            dispatch('setTheme', DARK_THEME)
                            break
                    }
                })
        }
    },
    mutations: {
        SET_THEME(state, theme) {
            StorageService.local.set(STORAGE_KEY, theme)
                .then(() => state.theme = theme)
        }
    }
}

export default theme
