import {createStore} from 'vuex'

import theme from '@/store/modules/theme'
import threads from '@/store/modules/threads'

export default createStore({
    modules: {
        theme,
        threads
    }
})
