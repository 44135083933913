import {createApp} from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import HttpService from '@/support/services/http'

import '@/assets/tailwind.css'

HttpService.init()

import timeMixin from '@/support/mixins/timeMixin'

createApp(App)
    .mixin(timeMixin)
    .use(store)
    .use(router)
    .mount('#app')
