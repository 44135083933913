<script>
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.locale('pt-br')

export default {
  methods: {
    $formatDate(date, format = 'LLLL', toAdd = 0, typeToAdd = 'minute') {
      dayjs.extend(localizedFormat)

      return dayjs(date).add(toAdd, typeToAdd).format(format)
    },
    $toHuman(date, action) {
      dayjs.extend(relativeTime)

      return action ? `${action} ${dayjs().to(dayjs(date))}` : dayjs().to(dayjs(date))
    }
  }
}
</script>
