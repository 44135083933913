<template>
  <div class="theme-toggler">
    <button @click="toggleThemeAction" class="rounded-full p-2.5 shadow" :title="buttonTitle">
      <svg v-if="themeGetter === 'system'" class="block fill-current text-slate-400" x="0px" y="0px" viewBox="0 0 32 29"
           key="system">
        <path
            d="M29.1,0H2.9C1.3,0,0,1.3,0,2.9v16.6c0,1.6,1.3,2.9,2.9,2.9h7v4.1H7.8c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2h16.4c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2h-2.1v-4.1h7c1.6,0,2.9-1.3,2.9-2.9V2.9C32,1.3,30.7,0,29.1,0z M19.7,26.5h-7.4v-4.1h7.4V26.5z M29.5,19.5c0,0.2-0.2,0.4-0.4,0.4H2.9c-0.2,0-0.4-0.2-0.4-0.4V2.9c0-0.2,0.2-0.4,0.4-0.4h26.3c0.2,0,0.4,0.2,0.4,0.4V19.5z"/>
      </svg>
      <svg v-else-if="themeGetter === 'dark'" class="block fill-current text-slate-200" x="0px" y="0px" viewBox="0 0 32 32"
           key="dark">
        <path
            d="M29.2,20.5c-4.2,1.7-9.2,0.6-12.3-2.7c-2-2.2-3.1-5-3-7.9c0.1-3,1.4-5.7,3.5-7.7c0.4-0.3,0.5-0.9,0.3-1.4C17.6,0.3,17.1,0,16.6,0h-0.5c-0.1,0-0.1,0-0.2,0C9.5,0,3.7,3.9,1.2,9.9C-2.2,18,1.7,27.4,9.9,30.8c2,0.8,4.1,1.2,6.1,1.2c6.3,0,12.2-3.7,14.8-9.9c0.2-0.5,0.1-1-0.3-1.3C30.2,20.4,29.6,20.3,29.2,20.5z M10.8,28.5C3.9,25.6,0.6,17.7,3.5,10.8c1.8-4.4,5.7-7.4,10.3-8.2c-1.4,2.1-2.2,4.5-2.3,7.1c-0.1,3.6,1.2,7.1,3.6,9.7c3.1,3.3,7.7,4.8,12.1,4.1C23.7,28.8,16.8,31,10.8,28.5z"/>
        <path
            d="M20.9,7.4c1.1,0,2.1,0.9,2.1,2.1c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2c0-1.1,0.9-2.1,2.1-2.1c0.7,0,1.2-0.6,1.2-1.2s-0.6-1.2-1.2-1.2c-1.1,0-2.1-0.9-2.1-2.1c0-0.7-0.6-1.2-1.2-1.2S23,2.2,23,2.9c0,1.1-0.9,2.1-2.1,2.1c-0.7,0-1.2,0.6-1.2,1.2S20.2,7.4,20.9,7.4z"/>
        <path
            d="M30.8,13.1h-0.4v-0.4c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v0.4h-0.4c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.2,1.2,1.2h0.4V16c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-0.4h0.4c0.7,0,1.2-0.6,1.2-1.2S31.4,13.1,30.8,13.1z"/>
      </svg>
      <svg v-else-if="themeGetter === 'light'" class="block fill-current text-slate-400" x="0px" y="0px" viewBox="0 0 32 32"
           key="light">
        <path
            d="M16,23.8c-4.3,0-7.8-3.5-7.8-7.8s3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8S20.3,23.8,16,23.8z M16,10.7c-2.9,0-5.3,2.4-5.3,5.3c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3C21.3,13.1,18.9,10.7,16,10.7z"/>
        <path
            d="M16,32c-0.7,0-1.2-0.6-1.2-1.2v-1.6c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2v1.6C17.2,31.4,16.7,32,16,32z M5.5,27.7c-0.3,0-0.6-0.1-0.9-0.4c-0.5-0.5-0.5-1.3,0-1.7l1.1-1.1C6.3,24,7,24,7.5,24.5C8,25,8,25.7,7.5,26.2l-1.1,1.1C6.1,27.6,5.8,27.7,5.5,27.7z M26.5,27.7c-0.3,0-0.6-0.1-0.9-0.4l-1.1-1.1c-0.5-0.5-0.5-1.3,0-1.7c0.5-0.5,1.3-0.5,1.7,0l1.1,1.1c0.5,0.5,0.5,1.3,0,1.7C27.1,27.6,26.8,27.7,26.5,27.7z M30.8,17.2h-1.6c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2h1.6c0.7,0,1.2,0.6,1.2,1.2C32,16.7,31.4,17.2,30.8,17.2z M2.9,17.2H1.2C0.6,17.2,0,16.7,0,16c0-0.7,0.6-1.2,1.2-1.2h1.6c0.7,0,1.2,0.6,1.2,1.2C4.1,16.7,3.6,17.2,2.9,17.2z M6.6,7.9C6.3,7.9,6,7.8,5.8,7.5L4.6,6.4c-0.5-0.5-0.5-1.3,0-1.7s1.3-0.5,1.7,0l1.1,1.1C8,6.3,8,7,7.5,7.5C7.3,7.8,7,7.9,6.6,7.9z M25.4,7.9c-0.3,0-0.6-0.1-0.9-0.4C24,7,24,6.3,24.5,5.8l1.1-1.1c0.5-0.5,1.3-0.5,1.7,0c0.5,0.5,0.5,1.3,0,1.7l-1.1,1.1C26,7.8,25.7,7.9,25.4,7.9z M16,4.1c-0.7,0-1.2-0.6-1.2-1.2V1.2C14.8,0.6,15.3,0,16,0c0.7,0,1.2,0.6,1.2,1.2v1.6C17.2,3.6,16.7,4.1,16,4.1z"/>
      </svg>
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import {
  DARK_THEME,
  LIGHT_THEME,
  SYSTEM_THEME
} from '@/support/constants/theme'

export default {
  name: 'ThemeToggler',
  computed: {
    ...mapGetters({
      themeGetter: 'theme/theme'
    }),
    buttonTitle() {
      switch (this.themeGetter) {
        case DARK_THEME:
          return 'Alterar para modo claro'
        case LIGHT_THEME:
          return 'Alterar para tema do sistema'
        case SYSTEM_THEME:
          return 'Alterar para modo escuro'
        default:
          return '=)'
      }
    },
    systemColorsScheme() {
      return window.matchMedia('(prefers-color-scheme: dark)')
    }
  },
  methods: {
    ...mapActions({
      toggleThemeAction: 'theme/toggleTheme'
    }),
    setLightTheme() {
      document.querySelector('html').classList.remove('dark')
    },
    setDarkTheme() {
      document.querySelector('html').classList.add('dark')
    },
    setSystemTheme() {
      this.systemColorsScheme.matches ?
          this.setDarkTheme() :
          this.setLightTheme()
    },
    systemColorPreferenceUpdated() {
      if (this.themeGetter === SYSTEM_THEME)
        this.setSystemTheme()
    }
  },
  watch: {
    themeGetter(theme) {
      switch (theme) {
        case DARK_THEME:
          this.setDarkTheme()
          break
        case LIGHT_THEME:
          this.setLightTheme()
          break
        case SYSTEM_THEME:
          this.setSystemTheme()
          break
      }
    }
  },
  mounted() {
    this.systemColorsScheme
        .addListener(this.systemColorPreferenceUpdated)
  },
  unmounted() {
    this.systemColorsScheme
        .removeListener(this.systemColorPreferenceUpdated)
  }
}
</script>

<style lang="scss" scoped>
.theme-toggler {
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
